import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { XS_QUERY, SM_AND_DOWN_QUERY } from '../../configs/breakpoints';
import { ExternalButton } from '../shared/ExternalButton';
import { Card } from './Card';
import campaignImage1 from './img_campaign_1.png';
import campaignImage2 from './img_campaign_2.png';
import campaignImage3 from './img_campaign_3.png';
import campaignImage4 from './img_campaign_4.png';
import campaignImage5 from './img_campaign_5.png';
import campaignImage6 from './img_campaign_6.png';
import campaignImage7 from './img_campaign_7.png';
import campaignImage8 from './img_campaign_8.png';
import campaignImage9 from './img_campaign_9.png';
import campaignImage10 from './img_campaign_10.png';
import campaignImage11 from './img_campaign_11.png';
import campaignImage12 from './img_campaign_12.png';
import campaignImage13 from './img_campaign_13.png';
import campaignImage14 from './img_campaign_14.png';
import campaignImage15 from './img_campaign_15.png';
import campaignImage16 from './img_campaign_16.png';
import campaignImage17 from './img_campaign_17.png';
import campaignImage18 from './img_campaign_18.png';
import campaignImage19 from './img_campaign_19.png';
import campaignImage20 from './img_campaign_20.png';
import campaignImage21 from './img_campaign_21.png';
import campaignImage22 from './img_campaign_22.png';
import campaignImage23 from './img_campaign_23.png';
import campaignImage24 from './img_campaign_24.png';
import campaignImage25 from './img_campaign_25.png';
import campaginSerafim from './img_campaign_serafim.png';
import campaignRakuten from './img_campaign_rakuten.png';
import campaignRabbit from './img_campaign_rabbit.png';
import campaignFunaicare from './img_campaign_funaicare.png';
import campaignKFC from './img_campaign_KFC.png';
import campaignHooters from './img_campaign_Hooters.png';
import campaignTibame from './img_campaign_tibame.png';
import campaignUtilityBonus from './img_campaign_utility_bonus.png';
import { StyledWebWidth } from '../../globalStyles';

const StyledContainer = styled(StyledWebWidth)`
  background-color: black;
  position: relative;
  width: 100%;
  margin: auto;
  background: black;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  @media ${XS_QUERY} {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledContent = styled.div`
  padding: 100px 0;
  width: 100%;

  @media ${XS_QUERY} {
    padding: 24px;
  }
`;

const StyledTitleContainer = styled.div`
  color: white;
  margin-bottom: 48px;
`;

const StyledTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: white;
  margin-bottom: 12px;

  @media ${XS_QUERY} {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }
`;

const StyledDescription = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  @media ${XS_QUERY} {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
`;

const StyledCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30%);
  justify-content: space-between;
  justify-items: space-between;

  @media ${SM_AND_DOWN_QUERY} {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

const utilityCategory = {
  ACCOMMODATION: 'accommodation',
  DRINK: 'drink',
  SHOPPING: 'shopping',
  FOOD: 'food',
};

const Campaign = () => {
  const { t } = useTranslation();
  const cardList = [
    {
      id: 1,
      image: campaignImage1,
      title: 'W Taipei',
      description: t('Free one night stay (+exclusive service package)'),
      location: t('W Taipei hotel'),
      quantity: '1',
      utilityCategory: utilityCategory.ACCOMMODATION,
      redemptionRule: t('The Uni Pass holder of 50,000th redemption for all utilities'),
      notice: t('You will get a Bonus NFT for redemption after 1/1.'),
    },
    {
      id: 'TBW utility bonus (Limited)',
      image: campaignUtilityBonus,
      title: t('TBW utility bonus (Limited)'),
      description: t('Free drink/hot pot/Yakiniku voucher*1'),
      location: t('7 Eleven, Family Mart, 燒肉同話, 小蒙牛頂級麻辣養生鍋'),
      quantity: '250',
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('Uni Pass total level 10+ & redeemed coupons of SYNTREND & Timberland'),
      notice: t(
        'You will get a Bonus B~H NFT for the voucher redemption. (one random NFT for each participant)',
      ),
    },
    {
      id: 2,
      image: campaignImage2,
      title: '7 ELEVEN & Family Mart',
      description: t('Free drink*1 (Latte, black tea, four season green tea)'),
      location: t('7 Eleven or Family Mart convenient store'),
      quantity: '250',
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('Uni Pass total level 10+'),
      notice: t(
        'You can redeem a Bonus B~F NFT for the drink redemption. (1 kind of Bonus NFT for 1 holder)',
      ),
    },
    {
      id: 3,
      image: campaignImage3,
      title: t('Timberland (Start from 12/1)'),
      description: t('NTD $500 off voucher'),
      location: t('Timberland offline store'),
      quantity: '500',
      utilityCategory: utilityCategory.SHOPPING,
      redemptionRule: t('shopping level 3'),
      notice: t('You can redeem a Bonus A NFT for the voucher redemption.'),
    },
    {
      id: 4,
      image: campaignImage4,
      title: 'SYNTREND-三創生活',
      description: t('NTD $50 off coupon (Minimum purchase amount: NTD$ 500)'),
      location: t('Syntrend Creative Park'),
      quantity: '2000',
      utilityCategory: utilityCategory.SHOPPING,
      redemptionRule: t('free'),
    },
    {
      id: 5,
      image: campaignImage5,
      title: 'Reebok',
      description: t('NTD $100 off coupon (Minimum purchase amount: NTD$ 1,000)'),
      location: t('Reebok online shop'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.SHOPPING,
      redemptionRule: t('free'),
    },
    {
      id: 6,
      image: campaignImage6,
      title: 'Uni Pass vending machine',
      description: t('Free drink*1'),
      location: t('Meet Taipei Web3+ booth (11/30~12/2)'),
      quantity: '500~700',
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 7,
      image: campaignImage7,
      title: '再睡5分鐘',
      description: t('Buy one, get one free (Specific item & time period）'),
      location: t('再睡5分鐘 drink shop'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 8,
      image: campaignImage8,
      title: 'Verse',
      description: t('10% off coupon'),
      location: t('Verse online shop'),
      quantity: '100',
      utilityCategory: utilityCategory.SHOPPING,
      redemptionRule: t('free'),
    },
    {
      id: 9,
      image: campaignImage9,
      title: 'Sleep no more',
      description: t('Underlink free drink voucher*2(Minimum order required)'),
      location: t('Sleep no more cafe'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: '毛寶兔',
      image: campaignRabbit,
      title: '毛寶兔',
      description: t('NTD $100 off coupon (Minimum purchase amount: NTD$ 799)'),
      location: t('毛寶兔 online shop'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.SHOPPING,
      redemptionRule: t('free'),
    },
    {
      id: 'Taiwan Rakuten',
      image: campaignRakuten,
      title: t('Taiwan Rakuten'),
      description: t('NTD $50/$120/$300 off coupon (Minimum purchase amount required)'),
      location: t('Rakuten online shop'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.SHOPPING,
      redemptionRule: t('free'),
    },
    {
      id: 'Serafim',
      image: campaginSerafim,
      title: 'Serafim',
      description: t('NTD $100 off coupon'),
      location: t('Serafim online shop'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.SHOPPING,
      redemptionRule: t('free'),
    },
    {
      id: 10,
      image: campaignImage10,
      title: '房間餐酒',
      description: t('Free shot*1 (Minimum order required)'),
      location: t('房間餐酒 restaurant'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 11,
      image: campaignImage11,
      title: '枋 Square',
      description: t('Free shot*1 (Minimum order required)'),
      location: t('枋 Square bar'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 12,
      image: campaignImage12,
      title: 'Coya',
      description: t('Free service charge for  purchase'),
      location: t('Coya bar'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 13,
      image: campaignImage13,
      title: 'Hook',
      description: t('NTD $100 off coupon'),
      location: t('Hook bar'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 14,
      image: campaignImage14,
      title: 'Bar PUN',
      description: t('Free shot*1 (Minimum order required)'),
      location: t('Bar PUN'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 15,
      image: campaignImage15,
      title: '墨子',
      description: t('Free shot*2 (Minimum order required)'),
      location: t('墨子 bar'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 16,
      image: campaignImage16,
      title: 'Bar ATM',
      description: t('Free shot*1 (Minimum order required)'),
      location: t('Bar ATM'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 17,
      image: campaignImage17,
      title: 'nest by PUN',
      description: t('Free shot*1 (Minimum order required)'),
      location: t('nest by PUN bar'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 18,
      image: campaignImage18,
      title: '小醉好',
      description: t('Free shot & truffle fries*1 (Minimum order required)'),
      location: t('小醉好 bar'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 19,
      image: campaignImage19,
      title: 'MQ (Marquee Taipei)',
      description: t('50% Discount on 2nd same Item'),
      location: t('MQ bar'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 20,
      image: campaignImage20,
      title: '如嬌花膠雞鍋物',
      description: t('Free seafood platter*1 (Minimum order required)'),
      location: t('如嬌花膠雞鍋物 restaurant'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.FOOD,
      redemptionRule: t('free'),
    },
    {
      id: 'KFC',
      image: campaignKFC,
      title: t('KFC'),
      description: t('NTD $50 off coupon (Minimum purchase amount: NTD$ 250)'),
      location: t('KFC website'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.FOOD,
      redemptionRule: t('free'),
    },
    {
      id: 'Hooters',
      image: campaignHooters,
      title: 'Hooters',
      description: t('Free chicken wings*6 (Order amount over NTD$ 1,000)'),
      location: t('Hooters restaurant'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.FOOD,
      redemptionRule: t('free'),
    },
    {
      id: 'Funaicare',
      image: campaignFunaicare,
      title: '船井生醫',
      description: t('NTD $200 off coupon (Minimum purchase amount: NTD$ 2,000)'),
      location: t('船井生醫 online shop'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.SHOPPING,
      redemptionRule: t('free'),
    },
    {
      id: 21,
      image: campaignImage21,
      title: 'Underlink',
      description: t('Free cocktail*1 (After entering Underlink)'),
      location: t('Underlink night club'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.DRINK,
      redemptionRule: t('free'),
    },
    {
      id: 'TibaMe',
      image: campaignTibame,
      title: '緯育 TibaMe',
      description: t('NTD $100/$300/$500 off coupon (Minimum purchase amount required)'),
      location: t('緯育 TibaMe online shop'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.SHOPPING,
      redemptionRule: t('free'),
    },
    {
      id: 22,
      image: campaignImage22,
      title: 'W Taipei',
      description: t(
        'Exclusive booking package (Free breakfast + USD$100 voucher*1 ) *Package could be modified according to hotel’s policy',
      ),
      location: t('W Taipei hotel'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.ACCOMMODATION,
      redemptionRule: t('free'),
    },
    {
      id: 23,
      image: campaignImage23,
      title: t('MANDARIN ORIENTAL Taipei'),
      description: t(
        'Exclusive booking package (Free breakfast + USD$100 voucher*1 ) *Package could be modified according to hotel’s policy',
      ),
      location: t('MANDARIN ORIENTAL hotel'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.ACCOMMODATION,
      redemptionRule: t('free'),
    },
    {
      id: 24,
      image: campaignImage24,
      title: t('InterContinental Kaohsiung'),
      description: t(
        'Exclusive booking package (Free breakfast + NTD$2,888 voucher*1 ) *Package could be modified according to hotel’s policy',
      ),
      location: t('InterContinental hotel'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.ACCOMMODATION,
      redemptionRule: t('free'),
    },
    {
      id: 25,
      image: campaignImage25,
      title: t('The Tokyo Edition, Toranomon'),
      description: t(
        'Exclusive booking package (Free breakfast + USD$100 voucher*1 ) *Package could be modified according to hotel’s policy',
      ),
      location: t('The Tokyo Edition hotel'),
      quantity: t('no limit'),
      utilityCategory: utilityCategory.ACCOMMODATION,
      redemptionRule: t('free'),
    },
  ];

  const [collapse, setCollapse] = useState(true);
  const preList = cardList.slice(0, 6);
  const postList = cardList.slice(6);
  return (
    <StyledContainer id="campaigns">
      <StyledContent>
        <StyledTitleContainer>
          <StyledTitle>{t('Uni Pass Utility')}</StyledTitle>
          <StyledDescription>
            {t('Owning a Uni Pass, you can enjoy the following exciting values not only in Taipei')}
          </StyledDescription>
        </StyledTitleContainer>
        <StyledCardContainer>
          {preList.map((campaign) => (
            <Card
              key={campaign.id}
              title={campaign.title}
              image={campaign.image}
              description={campaign.description}
              dotColor={campaign.dotColor}
              location={campaign.location}
              quantity={campaign.quantity}
              utilityCategory={campaign.utilityCategory}
              redemptionRule={campaign.redemptionRule}
              notice={campaign.notice}
            />
          ))}
          {!collapse &&
            postList.map((campaign) => (
              <Card
                key={campaign.id}
                title={campaign.title}
                image={campaign.image}
                description={campaign.description}
                dotColor={campaign.dotColor}
                location={campaign.location}
                quantity={campaign.quantity}
                utilityCategory={campaign.utilityCategory}
                redemptionRule={campaign.redemptionRule}
                canGetNft={campaign.canGetNft}
              />
            ))}
        </StyledCardContainer>
        {postList.length > 0 && (
          <ExternalButton
            onClick={() => {
              setCollapse(!collapse);
            }}
          >
            {collapse ? t('SEE MORE') : t('SHOW LESS')}
          </ExternalButton>
        )}
      </StyledContent>
    </StyledContainer>
  );
};

export default Campaign;
