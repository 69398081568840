import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ClipboardJS from 'clipboard';
import iconCopy from '../../assets/icon_copy.svg';
import { XS_QUERY } from '../../configs/breakpoints';

export const StyledButtonGroup = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;

  @media ${XS_QUERY} {
    margin-top: 24px;
  }
`;

export const StyledRedreamerButton = styled.div`
  width: 160px;
  height: 43px;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.greyColor : theme.colors.brightDreamColor};
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 43px;
  text-align: center;
  color: ${({ theme, disabled }) => (disabled ? 'black' : theme.colors.hopeColor)};
  margin-right: 24px;

  a {
    color: ${({ disabled }) => (disabled ? 'black' : 'white')};
    display: block;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const StyledCopyButton = styled.button`
  width: 160px;
  height: 43px;
  background: ${({ theme }) => theme.colors.brightDreamColor};
  background: black;
  border: 1px solid ${({ theme }) => theme.colors.brightDreamColor};
  cursor: pointer;

  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 43px;
  text-align: center;
  color: ${({ theme }) => theme.colors.brightDreamColor};
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    background: url(${iconCopy}) no-repeat;
    width: 40px;
    height: 40px;
    display: block;
    background-position-y: 50%;
    background-position-x: 50%;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.greyColor};
    border: 1px solid ${({ theme }) => theme.colors.greyColor};
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }

    &:active {
      background: unset;
    }
  }
`;

export const ButtonGroup = ({ text = '', disabled = false }) => {
  const { t } = useTranslation();
  const copyBtnRef = useRef(null);
  const [success, setSuccess] = useState(false);

  let clipboard;
  useEffect(() => {
    if (copyBtnRef.current) {
      const clipboard = new ClipboardJS(copyBtnRef.current, {
        text: () => text,
      });

      clipboard.on('success', () => {
        setSuccess(true);
      });
    }
    return () => clipboard && clipboard.destroy && clipboard.distroy();
  }, [copyBtnRef.current, text]);

  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <StyledButtonGroup>
      <StyledRedreamerButton disabled={disabled}>
        <a
          href="https://unipass-passport.redreamer.io/"
          target="_blank"
          rel="noreferrer noopener"
          onClick={handleClick}
        >
          {t('Redeem')}
        </a>
      </StyledRedreamerButton>
      <StyledCopyButton disabled={disabled} ref={copyBtnRef}>
        {t('Copy Link')}
      </StyledCopyButton>
    </StyledButtonGroup>
  );
};
