import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import imgBackground from './img_gameplay_background.svg';
import imgBackground2 from './img_gameplay_background2.svg';
import imgStep301 from './img_step3_01.png';
import imgStep302 from './img_step3_02.png';
import imgBulletinYellow from '../../assets/bulletin_yellow.svg';
import { XS_QUERY } from '../../configs/breakpoints';
import { ExternalButton } from '../shared/ExternalButton';
import { useResponsive } from '../../hooks/useResponsive';
import { Step2Carousel } from './Step2Carousel';
import { ButtonGroup } from './ButtonGroup';
import { Disclaimer } from './Disclaimer';
import imgStep4 from './img_step4.png';
import imgStep4Mobile from './img_step4_mobile.png';
import { StyledWebWidth } from '../../globalStyles';

const StyledContainer = styled.div`
  background-color: black;
  position: relative;
  width: 100%;
  margin: auto;
  background: url(${imgBackground}), url(${imgBackground2}), url(${imgBackground});
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 60%, 60%, 60%;
  background-position: left top, right center, left bottom;
  margin-bottom: 144px;

  @media ${XS_QUERY} {
    margin-bottom: 0;
    width: 100%;
    overflow: hidden;
  }
`;

const StyledContent = styled(StyledWebWidth)`
  padding: 48px 0;
  padding-bottom: 0;
  width: 100%;

  @media ${XS_QUERY} {
    padding: 24px;
  }
`;

const StyledTitleContainer = styled.div`
  color: white;
  margin-bottom: 48px;
`;

const StyledTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: white;
  margin-bottom: 12px;
`;

const StyledContentContainer = styled.div`
  display: flex;

  @media ${XS_QUERY} {
    flex-direction: column;
  }
`;

const StyledDescription = styled.div`
  color: white;
  width: 100%;

  .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.brightDreamColor};

    @media ${XS_QUERY} {
      font-size: 32px;
      line-height: 48px;
      justify-content: center;
    }

    &:before {
      display: flex;
      align-items: center;
      content: '';
      height: 30px;
      width: 30px;
      margin-right: 12px;
      background: url(${imgBulletinYellow}) no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
    }
  }

  .jcc {
    justify-content: center;
    text-align: center;
  }

  .sub-title {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 38px;
    margin-left: 42px;

    @media ${XS_QUERY} {
      font-size: 16px;
      margin-left: 0;
    }
  }

  .step2-sub-title {
    max-width: 963px;
    margin: 0 auto 32px auto;
    line-height: 60px;

    @media ${XS_QUERY} {
      line-height: 24px;
    }
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;

    .link {
      border-bottom: 1px solid white;

      a {
        color: white;

        &:visited {
          color: white;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .q-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 2;

    @media ${XS_QUERY} {
      font-size: 16px;
    }
  }

  .contract {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 24px;
    display: flex;

    a {
      text-decoration-line: underline;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.hopeColor};
      margin-right: 6px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .q-answer {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 12px;

    @media ${XS_QUERY} {
      font-size: 14px;
    }

    .link {
      border-bottom: 1px solid white;

      a {
        color: white;
        &:visited {
          color: white;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .step2-ans {
    margin-bottom: 32px;
  }

  .list {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    padding-left: 24px;

    li {
      list-style-type: decimal;
    }

    a {
      color: white;
      border-bottom: 1px solid white;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .emphasis {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.roseColor};
  }
`;

const StyledExternalButton = styled(ExternalButton)`
  margin: 32px 0 38px 0;

  @media ${XS_QUERY} {
    background: rgb(22, 22, 26, 0.5);
  }
`;

const StyledSoulImage = styled.img`
  border: 1px solid white;
  margin-left: 24px;

  @media ${XS_QUERY} {
    margin: 24px 0 0;
  }
`;

const StyledImageStep3Container = styled.div`
  display: flex;
  margin-top: 24px;

  @media ${XS_QUERY} {
    flex-direction: column;
  }

  .img {
    width: 100%;

    &:not(:last-child) {
      margin-right: 24px;
    }

    @media ${XS_QUERY} {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    img {
      width: 100%;
    }
  }
`;

const StyledNFTWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  @media ${XS_QUERY} {
    flex-direction: row;
  }
`;

const StyledImageStep4Container = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  @media ${XS_QUERY} {
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 24px;
    }
  }

  .img {
    width: 100%;
    border: 1px solid white;

    &:not(:last-child) {
      margin-right: 24px;
    }

    @media ${XS_QUERY} {
      &:not(:last-child) {
        margin-bottom: 24px;
        margin-right: 0;
      }
    }

    img {
      width: 100%;
    }
  }
`;

const StyledImageStep1 = styled.img`
  width: 468px;
  height: 468px;
  margin-left: -16px;
  margin-right: 72px;

  @media ${XS_QUERY} {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 72px;
  }
`;

const StyledChannel = styled.div`
  padding: 8px 16px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
  color: white;
  display: inline-block;
  background: ${({ theme }) => theme.colors.brightDreamColor};
`;

const StyledStep4Img = styled.img`
  width: 100%;
`;

const Gameplay = () => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();

  return (
    <StyledContainer id="gameplay">
      <StyledContent>
        <StyledTitleContainer>
          <StyledTitle>{t('How to Engage')}</StyledTitle>
          <StyledContentContainer>
            <StyledImageStep1
              src={
                isMobile
                  ? 'https://mainnet-cdn.redreamer.io/slackbot/images/nft_uni-pass-demo_mobile_c1e03390-b0fa-46d6-b8a7-6eaad490e49f.png'
                  : 'https://mainnet-cdn.redreamer.io/slackbot/images/nft_uni-pass-demo_7888bcdc-22a0-4185-b39d-9be3c6df15f5.png'
              }
            />
            <StyledDescription>
              <div className="title">Step 1</div>
              <div className="sub-title">{t('Collect Uni Pass NFT')}</div>
              <StyledChannel>Online channels</StyledChannel>
              <div className="q-answer">
                {t(
                  'To welcome everyone to get in on the fun, follow the next few steps, and we’ll airdrop you an Uni Pass NFT',
                )}
              </div>
              <ol className="list">
                <li>
                  <a href="https://app.joy.id/" target="_blank" rel="noreferrer noopener">
                    {t('Download JoyID Wallet')}&nbsp;
                  </a>
                  {t('or Metamask')}
                </li>
                <li>{t('Go to MetaCRM and fill out a survey')}</li>
              </ol>
              <StyledExternalButton
                href="https://metaform.xyz/user/654dbd827e72ae2219ce84b3"
                icon="right-top"
              >
                {t('SURVEY LINK')}
              </StyledExternalButton>
              <StyledChannel>Offline channels</StyledChannel>
              <div className="q-answer">
                {t('Find us and get Uni Pass NFT at blockchain events!')}
              </div>
              <ol className="list">
                <li>{t('Meet Taipei Web3+ booth')}</li>
                <li>{t('NFT Taipei RE:DREAMER booth')}</li>
                <li>{t('Taipei blockchain week RE:DREAMER booth')}</li>
              </ol>
            </StyledDescription>
          </StyledContentContainer>
        </StyledTitleContainer>
      </StyledContent>
      <StyledContent id="gameplay_step2">
        <StyledDescription>
          <div className={`${isMobile ? 'title' : 'title jcc'}`}>Step 2</div>
          <div className="sub-title jcc step2-sub-title">
            {t('Select a campaign to redeem your Uni Pass NFT!（STARTING ON 11/27 00:00）')}
          </div>
          <div className="q-answer jcc step2-ans">
            {t(
              'If you are using JoyID Passkey Wallet, please directly visit RE:DREAMER redeem page',
            )}
          </div>
          <Step2Carousel type="joyid" />
          <div className="q-answer jcc">{t('If you are using Metamask')}</div>
          <div className="q-answer jcc">{t('click Redeem')}</div>
          <ButtonGroup text="https://unipass-passport.redreamer.io/" />
          <Step2Carousel type="metamask" />
        </StyledDescription>
      </StyledContent>
      <StyledContent>
        <StyledDescription>
          <div className="title jcc">Step 3</div>
          <div className="sub-title jcc">{t('Enjoy perks from Uni Pass NFT!')}</div>
          <div className="q-answer jcc">
            {t(
              'Show QR Codes at checkouts, insert Promo Codes at related applications, or click on the Links to complete redemption.',
            )}
          </div>
        </StyledDescription>
        <StyledImageStep3Container>
          <div className="img">
            <img alt="step3 example 1" src={imgStep301} />
          </div>
          <div className="img">
            <img alt="step3 example 2" src={imgStep302} />
          </div>
        </StyledImageStep3Container>
      </StyledContent>
      <StyledContent>
        <StyledDescription>
          <div className="title jcc">Step 4</div>
          <div className="sub-title jcc">{t('Each redemption will upgrade your Uni Pass !')}</div>
          <div className="q-answer jcc  step2-ans">
            {t('You will unlock higher level perks after achieving specific level.')}&nbsp;
          </div>
        </StyledDescription>
        <StyledNFTWrapper>
          <StyledStep4Img src={isMobile ? imgStep4Mobile : imgStep4} alt="imgStep4" />
        </StyledNFTWrapper>
      </StyledContent>
      <StyledContent>
        <Disclaimer />
      </StyledContent>
    </StyledContainer>
  );
};

export default Gameplay;
