import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { XS_QUERY } from '../../configs/breakpoints';
import imgBackground from './img_campaign_background.svg';
import imgCoya from './img_partners_coya.svg';
import imgHeartening from './img_partners_heartening.svg';
import imgHook from './img_partners_hook.svg';
import imgJoyid from './img_partners_joyid.svg';
import imgMetacrm from './img_partners_metacrm.svg';
import imgMozi from './img_partners_mozi.svg';
import imgNest from './img_partners_nest.svg';
import imgNoneLand from './img_partners_none_land.svg';
import imgOcard from './img_partners_ocard.svg';
import imgPunTaipei from './img_partners_pun_taipei.svg';
import imgRedreamer from './img_partners_redreamer.svg';
import imgReebok from './img_partners_reebok.svg';
import imgSleepMore5Min from './img_partners_sleep_more_5min.svg';
import imgSleepNoMore from './img_partners_sleep_no_more.svg';
import imgSphere from './img_partners_sphere.svg';
import imgSquare from './img_partners_square.svg';
import imgSyntrend from './img_partners_syntrend.svg';
import imgTimberland from './img_partners_timberland.svg';
import imgVerse from './img_partners_verse.svg';
import imgXiao from './img_partners_xiao.svg';
import imgRoom from './img_partners_room.svg';
import imgBarAtm from './img_partners_bar_atm.svg';
import imgPaNews from './img_partners_panews.svg';
import imgWeb3Plus from './img_partners_web3_plus.svg';
import imgGrowing3 from './img_partners_growing3.svg';
import imgUnderlink from './img_partners_underlink.svg';
import imgHotpot from './img_partners_hotpot.svg';
import imgMQ from './img_partners_mq.svg';
import imgDEMET from './img_partners_demet.svg';
import imgHrsw from './img_partners_hrsw.svg';
import imgBD from './img_partners_bd.svg';
import imgKFC from './img_partners_kfc.svg';
import imgRAKUTEN from './img_partners_rakuten.svg';
import imgSerafim from './img_partners_serafim.svg';
import imgTimbame from './img_partners_tibame.svg';
import imgIChannels from './img_partners_ichannels.svg';
import imgPayeasy from './img_partners_payeasy.svg';
import imgHooters from './img_partners_hooters.svg';
import imgGenMaxLab from './img_partners_genmaxlab.svg';
import imgPolygon from './img_partners_polygon.svg';

import { StyledWebWidth } from '../../globalStyles';
import imgBulletinYellow from '../../assets/bulletin_yellow.svg';

const StyledContainer = styled.div`
  background-color: black;
  position: relative;
  width: 100%;
  margin: auto;
  background: url(${imgBackground});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  @media ${XS_QUERY} {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledContent = styled(StyledWebWidth)`
  padding: 100px 0;
  width: 100%;

  @media ${XS_QUERY} {
    padding: 24px;
  }
`;

const StyledTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: ${({ theme }) => theme.colors.brightDreamColor};

  @media ${XS_QUERY} {
    margin-bottom: 12px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
  }
`;

const StyledTitleIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 10px;
`;

const StyledIcons = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 84px;
  }

  @media ${XS_QUERY} {
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  img {
    margin: 18px;

    @media ${XS_QUERY} {
      width: 80px;
      margin-bottom: 12px;
    }
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const hosts = [
  {
    name: 'redreamer',
    img: imgRedreamer,
  },
  {
    name: 'sphere',
    img: imgSphere,
  },
];

const partner = [
  {
    name: 'Polygon',
    img: imgPolygon,
  },
];

const techPartner = [
  {
    name: 'MetaCRM',
    img: imgMetacrm,
  },
  {
    name: 'Ocard',
    img: imgOcard,
  },
  {
    name: 'JoyID',
    img: imgJoyid,
  },
  {
    name: 'Heartening',
    img: imgHeartening,
  },
  {
    name: 'PayEasy',
    img: imgPayeasy,
  },
  {
    name: 'growing3',
    img: imgGrowing3,
  },
  {
    name: 'iChannels',
    img: imgIChannels,
  },
];

const utilityBrand = [
  {
    name: 'SynTrend',
    img: imgSyntrend,
  },
  {
    name: 'Timberland',
    img: imgTimberland,
  },
  {
    name: 'Reebok',
    img: imgReebok,
  },
  {
    name: 'kfc',
    img: imgKFC,
  },
  {
    name: 'rakuten',
    img: imgRAKUTEN,
  },
  {
    name: 'SleepMore5Min',
    img: imgSleepMore5Min,
  },
  {
    name: 'PunTaipei',
    img: imgPunTaipei,
  },
  {
    name: 'Nest',
    img: imgNest,
  },
  {
    name: 'Square',
    img: imgSquare,
  },
  {
    name: 'Room',
    img: imgRoom,
  },
  {
    name: 'BarAtm',
    img: imgBarAtm,
  },
  {
    name: 'Hook',
    img: imgHook,
  },
  {
    name: 'Verse',
    img: imgVerse,
  },
  {
    name: 'SleepNoMore',
    img: imgSleepNoMore,
  },
  {
    name: 'Coya',
    img: imgCoya,
  },
  {
    name: 'Mozi',
    img: imgMozi,
  },
  {
    name: 'Xiao',
    img: imgXiao,
  },
  {
    name: 'underlink',
    img: imgUnderlink,
  },
  {
    name: 'hotpot',
    img: imgHotpot,
  },
  {
    name: 'mq',
    img: imgMQ,
  },
  {
    name: 'demet',
    img: imgDEMET,
  },
  {
    name: 'hrsw',
    img: imgHrsw,
  },
  {
    name: 'BD',
    img: imgBD,
  },
  {
    name: 'serafim',
    img: imgSerafim,
  },
  {
    name: 'tibame',
    img: imgTimbame,
  },
  {
    name: 'hooters',
    img: imgHooters,
  },
];

const mediaPartners = [
  {
    name: 'NoneLand',
    img: imgNoneLand,
  },
  {
    name: 'PaNews',
    img: imgPaNews,
  },
  {
    name: 'Web3Plus',
    img: imgWeb3Plus,
  },
  {
    name: 'GenMaxLab',
    img: imgGenMaxLab,
  },
];

const Partners = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer id="partners">
      <StyledContent>
        <StyledRow>
          <StyledTitleIcon src={imgBulletinYellow} />
          <StyledTitle>{t('Hosts')}</StyledTitle>
        </StyledRow>
        <StyledIcons>
          {hosts.map((e) => (
            <img key={e.name} alt={e.name} src={e.img} />
          ))}
        </StyledIcons>
        <StyledRow>
          <StyledTitleIcon src={imgBulletinYellow} />
          <StyledTitle>{t('Partner')}</StyledTitle>
        </StyledRow>
        <StyledIcons>
          {partner.map((e) => (
            <img key={e.name} alt={e.name} src={e.img} />
          ))}
        </StyledIcons>
        <StyledRow>
          <StyledTitleIcon src={imgBulletinYellow} />
          <StyledTitle>{t('Tech Partners')}</StyledTitle>
        </StyledRow>
        <StyledIcons>
          {techPartner.map((e) => (
            <img key={e.name} alt={e.name} src={e.img} />
          ))}
        </StyledIcons>
        <StyledRow>
          <StyledTitleIcon src={imgBulletinYellow} />
          <StyledTitle>{t('Utility brand')}</StyledTitle>
        </StyledRow>
        <StyledIcons>
          {utilityBrand.map((e) => (
            <img key={e.name} alt={e.name} src={e.img} />
          ))}
        </StyledIcons>
        <StyledRow>
          <StyledTitleIcon src={imgBulletinYellow} />
          <StyledTitle>{t('Media Partners')}</StyledTitle>
        </StyledRow>
        <StyledIcons>
          {mediaPartners.map((e) => (
            <img key={e.name} alt={e.name} src={e.img} />
          ))}
        </StyledIcons>
      </StyledContent>
    </StyledContainer>
  );
};

export default Partners;
