import styled from 'styled-components';
import { useResponsive } from '../../hooks/useResponsive';
import { XS_QUERY } from '../../configs/breakpoints';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import imgJoyIDWallet1 from './JoyID_1.jpg';
import imgJoyIDWallet3 from './JoyID_3.jpg';
import imgJoyIDWallet4 from './JoyID_4.jpg';
import imgJoyIDWallet5 from './JoyID_5.jpg';
import imgJoyIDWallet6 from './JoyID_6.jpg';
import imgJoyIDWallet7 from './JoyID_7.jpg';
import imgJoyIDWallet8 from './JoyID_8.jpg';
import imgMetaMask1 from './Metamask_1.jpg';
import imgMetaMask2 from './Metamask_2.jpg';
import imgMetaMask3 from './Metamask_3.jpg';
import imgMetaMask4 from './Metamask_4.jpg';
import imgMetaMask5 from './Metamask_5.jpg';
import imgMetaMask6 from './Metamask_6.jpg';
import imgMetaMask7 from './Metamask_7.jpg';

const StyledContainer = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 48px;
`;

const StyledContent = styled.div`
  position: relative;
  width: 100%;
  padding: 36px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;

  @media ${XS_QUERY} {
    padding: 0px;
    border: none;
    &:not(:last-child) {
      margin-bottom: 24p;
    }
  }
`;

const StyledTitle = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  border-bottom: 2px solid white;
  margin-bottom: 24px;
`;

const StyledImageContainer = styled.div`
  width: 100%;

  padding: 24px;

  @media ${XS_QUERY} {
    padding: 6px;
  }

  .image {
    width: 100%;
  }
`;

const StyledSlider = styled(Slider)`
  width: 100%;

  .slick-dots {
    li {
      button {
        &:before {
          color: white;
        }
      }
    }
    li.slick-active {
      button {
        &:before {
          color: white;
        }
      }
    }
  }
`;

const sliderSettings = {
  speed: 500,
  dots: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 3,
};

export const Step2Carousel = ({ type = 'joyid' }) => {
  const { isMobile } = useResponsive();
  if (isMobile) {
    sliderSettings.slidesToShow = 2;
    sliderSettings.slidesToScroll = 2;
  } else {
    sliderSettings.slidesToShow = 3;
    sliderSettings.slidesToScroll = 3;
  }

  return (
    <StyledContainer>
      {type === 'joyid' && (
        <StyledContent>
          <StyledTitle>JoyID Passkey Wallet</StyledTitle>
          <StyledSlider {...sliderSettings}>
            <StyledImageContainer>
              <img className="image" alt="JoyID wallet Step" src={imgJoyIDWallet1} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="JoyID wallet Step" src={imgJoyIDWallet3} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="JoyID wallet Step" src={imgJoyIDWallet4} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="JoyID wallet Step" src={imgJoyIDWallet5} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="JoyID wallet Step" src={imgJoyIDWallet6} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="JoyID wallet Step" src={imgJoyIDWallet7} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="JoyID wallet Step" src={imgJoyIDWallet8} />
            </StyledImageContainer>
          </StyledSlider>
        </StyledContent>
      )}
      {type === 'metamask' && (
        <StyledContent>
          <StyledTitle>MetaMask</StyledTitle>
          <StyledSlider {...sliderSettings}>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask1} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask2} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask3} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask4} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask5} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask6} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask7} />
            </StyledImageContainer>
          </StyledSlider>
        </StyledContent>
      )}
    </StyledContainer>
  );
};
