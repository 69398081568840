import styled from 'styled-components';
import { SM_AND_DOWN_QUERY } from '../../configs/breakpoints';
import imgBulletinYellow from '../../assets/bulletin_yellow.svg';

const HEIGHT = 80;

export const StyledHeader = styled.header`
  padding-top: ${HEIGHT}px;
`;

export const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${HEIGHT}px;
  background-color: black;
  color: #b143ff;
  z-index: 5;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: auto;
  padding: 0 48px;
  font-size: 24px;
`;

export const StyledImage = styled.img`
  height: 100%;
  width: 157px;
  cursor: pointer;
`;

export const StyledGroup = styled.div`
  display: flex;
  height: auto;
  position: ${({ isSmallerThanTablet, open }) => {
    if (isSmallerThanTablet) {
      return 'absolute';
    } else {
      return 'relative';
    }
  }};
  flex-direction: ${({ isSmallerThanTablet }) => (isSmallerThanTablet ? 'column' : 'row')};
  justify-content: space-around;
  align-items: center;

  @media ${SM_AND_DOWN_QUERY} {
    padding: 24px;
    background-color: black;
    top: ${HEIGHT}px;
    width: 100vw;
    left: 0;
    overflow: hidden;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    height: ${({ open }) => (open ? 'auto' : '0')};
  }
`;

export const StyledItem = styled.a`
  position: relative;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: white;
  padding: 12px 18px;
  cursor: pointer;
  margin-right: 14px;
  border-radius: 4px;

  @media ${SM_AND_DOWN_QUERY} {
    margin-bottom: 24px;
    margin-right: 0px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.brightDreamColor};
  }
`;

export const StyledEarthItem = styled(StyledItem)`
  &:hover {
    background: transparent;
  }
`;

export const StyledRedreamerButton = styled.a`
  position: relative;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: white;
  padding: 12px 18px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 14px;
  background: ${({ theme }) => theme.colors.brightDreamColor};

  @media ${SM_AND_DOWN_QUERY} {
    margin-bottom: 24px;
    margin-right: 0px;
  }
`;

export const StyledBackDrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  opacity: ${({ hasBackDrop }) => (hasBackDrop ? 0.75 : 0)};
  z-index: 3;
  transition: opacity 0.3s;
`;

export const StyledDropdown = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledDropdownContent = styled.div`
  display: block;
  position: absolute;
  min-width: 160px;
  padding: 12px 16px;
  z-index: 1;
  background: #000000;
  border: 1px solid #ffffff;
  right: 0;
  top: 40px;

  @media ${SM_AND_DOWN_QUERY} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    right: auto;
    top: auto;
    border: none;
  }

  .item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.brightDreamColor};
    }

    &:not(:last-child) {
      margin-bottom: 12px;
      @media ${SM_AND_DOWN_QUERY} {
        margin-bottom: 0;
      }
    }

    &:before {
      display: flex;
      align-items: center;
      content: '';
      height: 30px;
      width: 30px;
      margin-right: 12px;
      background-position-x: 50%;
      background-position-y: 50%;
    }
  }

  .active {
    color: ${({ theme }) => theme.colors.brightDreamColor};

    &:before {
      display: flex;
      align-items: center;
      content: '';
      height: 30px;
      width: 30px;
      margin-right: 12px;
      background: url(${imgBulletinYellow}) no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
    }
  }
`;
