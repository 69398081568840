import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { XS_QUERY } from '../../configs/breakpoints';
import { useResponsive } from '../../hooks/useResponsive';
import { ExternalButton } from '../shared/ExternalButton';
import backgroundDesktop from './img_bg_event_desktop.png';
import backgroundMobile from './img_bg_event_mobile.png';
import nftTaipeiLogo from '../../assets/img_logo_nfttaipei.svg';

const StyledContainer = styled.div`
  display: flex;
  background-color: black;
  position: relative;
  width: 100%;
  margin: auto;

  @media ${XS_QUERY} {
    flex-direction: column;
  }
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 100px;
  width: 100%;

  @media ${XS_QUERY} {
    padding: 24px;
    flex-direction: column;
    background: url(${backgroundMobile});
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 20%;
    padding-bottom: 100px;
  }
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  align-items: start;
  color: white;

  @media ${XS_QUERY} {
    padding-right: 0px;
    width: 100%;
    align-items: center;
  }

  .title {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 24px;
  }

  .image {
    width: 100%;
    max-width: 200px;
    margin-bottom: 24px;
  }

  description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
  }
`;

const StyledImage = styled.img`
  flex-basis: 50%;
  flex-shrink: 1;
  width: 50%;
  object-fit: contain;
`;

const StyledExternalButton = styled(ExternalButton)`
  margin: 24px 0 0 0;

  @media ${XS_QUERY} {
    background: rgb(22, 22, 26, 0.5);
  }
`;

const Event = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  return (
    <StyledContainer>
      <StyledContent>
        <StyledInfo>
          <div className="title">{t('An Event Ｗith')}</div>
          <img className="image" alt="NFT Taipei logl" src={nftTaipeiLogo} />
          <div className="date">{t('Dec. 9 ~ 18, 2022 Taipei, Taiwan')}</div>
          <StyledExternalButton href="https://www.nft-taipei.com">
            {t('MORE INFO')}
          </StyledExternalButton>
        </StyledInfo>
        {!isMobile && <StyledImage src={backgroundDesktop} />}
      </StyledContent>
    </StyledContainer>
  );
};

export default Event;
