/*
  ---
  XS (phones) – 600px and below
  SM (small to medium tablets) – 601px to 960px
  MD (large tablets to laptop) – 961px to 1264px
  LG (desktop) – 1265px to 1904px
  XL (4k and ultra-wides) – 1905px and above
  ---
  Reference: https://material-ui.com/customization/breakpoints/
*/

const vw = { xs: 600, sm: 960, md: 1264, lg: 1904 };

/*
  breakpoints is detecting the min-width of the viewport
  ex: 601px is the breakpoint for SM
*/
const breakpoints = [
  '1px', // xs
  `${vw.xs + 1}px`, // sm
  `${vw.sm + 1}px`, // md
  `${vw.md + 1}px`, // lg
  `${vw.lg + 1}px`, // xl
];

const breakpointsAlias = {};

[
  breakpointsAlias.xs,
  breakpointsAlias.sm,
  breakpointsAlias.md,
  breakpointsAlias.lg,
  breakpointsAlias.xl,
] = breakpoints; // aliases

const colors = {
  brightDreamColor: '#9e8eea',
  brightDreamColorAlpha: 'rgba(177, 67, 255, 0.05)',
  darkDreamColor: '#762daa',
  hopeColor: '#fdf253',
  roseColor: '#ff2c82',
  roseColorAlpha: 'rgba(255, 44, 130, 0.05)',
  brainGreyColor: '#16161d',
  greyColor: '#d9d9d9',
  whiteColor: '#ffffff',
  blackColorAlpha60: 'rgba(0, 0, 0, 0.6)',
  blackColorAlpha32: 'rgba(0, 0, 0, 0.32)',
};

const metrics = {
  widthXS: vw.xs,
  widthSM: vw.sm,
  widthMD: vw.md,
  widthLG: vw.lg,
  containerPadding: 0,
  topbarHeight: 50,
  sidebarWidth: 270,
  menuItemHeight: 45,
};

const zIndexConfig = [
  'TABLE_HEAD',
  'DROPDOWN_PANEL',
  'PAGE_HEADER',
  'SITE_SIDEBAR',
  'SITE_TOP_BAR',
  'TOOLTIP',
  'BACKDROP',
  'MODAL',
];

const zIndex = (name) => {
  const i = zIndexConfig.indexOf(name);
  if (i === -1) {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error(`z index value not found for: ${name}`);
    }

    return 0;
  }

  return i + 2;
};

const theme = {
  breakpoints,
  colors,
  metrics,
  zIndex,
};

export default theme;
