import React from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, ResetStyle } from './globalStyles';
import { Navbar } from './components/Navbar';
import { Banner } from './components/Banner';
import { Campaign } from './components/Campaign';
import { Gameplay } from './components/Gameplay';
import { Partners } from './components/Partners';
import { Event } from './components/Event';
import { Footer, FooterInfo } from './components/Footer';
import theme from './configs/theme';

const StyledContainer = styled.div`
  background: black;
`;

const StyledSection = styled.div`
  background-color: black;
  position: relative;
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ResetStyle />
      <GlobalStyle />
      <StyledContainer>
        <Navbar />
        <StyledSection>
          <Banner />
          <Campaign />
          <Gameplay />
          <Partners />
          <FooterInfo />
          <Footer />
        </StyledSection>
      </StyledContainer>
    </ThemeProvider>
  );
}

export default App;
