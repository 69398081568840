import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { XS_QUERY } from '../../configs/breakpoints';
import { ExternalButton } from '../shared/ExternalButton';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-top: 123px;
`;

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 60px;
  background: rgba(177, 67, 255, 0.05);
  border: 1px solid rgba(217, 217, 217, 0.2);
  backdrop-filter: blur(5px);

  @media ${XS_QUERY} {
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledGroup = styled.div`
  display: flex;
  align-items: center;

  @media ${XS_QUERY} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  display: flex;
  align-items: center;
  width: 250px;
  flex-shrink: 0;

  @media ${XS_QUERY} {
    width: 100%;
    margin-bottom: 12px;
    margin-right: 0;
    font-size: 32px;
    line-height: 48px;
    justify-content: flex-start;
  }
`;

const StyledDescription = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const StyledExternalButton = styled(ExternalButton)`
  &&& {
    margin: 0 0 0 40px;
    width: 300px;

    @media ${XS_QUERY} {
      margin: 24px 0 0 0;
      width: 100%;
      background: rgb(22, 22, 26, 0.5);
    }
  }
`;

export const Disclaimer = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledContent>
        <StyledTitle>{t('Notice')}</StyledTitle>
        <StyledDescription>
          {t(
            'All web3 or supporting technology used by Uni Pass are not owned by Uni Pass, and Uni Pass is not able to control the actions or to monitor its information security. Please read the terms and conditions of use related to the use of the services and any related risk warnings provided by each service before using them.',
          )}
        </StyledDescription>
      </StyledContent>
      <br />
      <br />
      <StyledContent>
        <StyledGroup>
          <StyledTitle color="purple">{t('FAQ')}</StyledTitle>
          <StyledDescription>
            {t('Click “Document link” button to view more information about Uni Pass.')}
          </StyledDescription>
        </StyledGroup>
        <StyledExternalButton
          href="https://docs.google.com/document/d/1iPBoqbdDyCNtYtuGFfuAQr1BTNjjikbXkqyp82CvMWQ/edit"
          icon="right-top"
        >
          {t('DOCUMENT LINK')}
        </StyledExternalButton>
      </StyledContent>
    </StyledContainer>
  );
};
