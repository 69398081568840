import styled from 'styled-components';
import { XS_QUERY } from '../../configs/breakpoints';
import imgLogo from '../../assets/img_redreamer_logo.svg';
import iconDiscord from '../../assets/icon_discord.svg';
import iconTwitter from '../../assets/icon_twitter.svg';
import iconInstagram from '../../assets/icon_instagram.svg';
import iconFacebook from '../../assets/icon_facebook.svg';
import imgSphereLogo from './img_sphere_logo.svg';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 24px;
  padding: 0 100px;

  @media ${XS_QUERY} {
    padding: 0;
    flex-direction: column;
  }
`;

const StyledLogo = styled.a`
  display: flex;
  align-items: center;

  @media ${XS_QUERY} {
    margin-bottom: 32px;
  }

  .redreamer-logo {
    width: 100px;
    margin-right: 24px;
  }

  .sphere-logo {
    width: 230px;
    margin-right: 24px;

    @media ${XS_QUERY} {
      width: 155px;
    }
  }
`;

const StyledIconGroup = styled.div`
  display: flex;
  align-items: center;

  a {
    display: block;
    height: 40px;

    &:hover {
      opacity: 0.8;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }

    img {
      width: 40px;
      height: 100%;
    }
  }
`;

const redreamerList = [
  {
    key: 'twitter',
    default: iconTwitter,
    hidden: false,
    url: 'https://twitter.com/REDREAMER_Lab',
  },
  {
    key: 'discord',
    default: iconDiscord,
    hidden: false,
    url: 'https://discord.gg/2zJceWvkUT',
  },
  {
    key: 'instagram',
    default: iconInstagram,
    hidden: false,
    url: 'https://www.instagram.com/redreamer.io',
  },
  {
    key: 'facebook',
    default: iconFacebook,
    hidden: false,
    url: 'https://www.facebook.com/redreamer.io',
  },
];

const sphereList = [
  {
    key: 'twitter',
    default: iconTwitter,
    hidden: false,
    url: 'https://x.com/SPHERE_Phygital',
  },
  {
    key: 'instagram',
    default: iconInstagram,
    hidden: false,
    url: 'https://www.instagram.com/sphere_phygital',
  },
  {
    key: 'facebook',
    default: iconFacebook,
    hidden: false,
    url: 'https://www.facebook.com/spherecorp.meta',
  },
];

const FooterInfo = () => {
  return (
    <StyledContainer>
      <StyledLogo href="https://www.redreamer.io" target="_blank" rel="noreferrer noopener">
        <img alt="redreamer logo" src={imgLogo} className="redreamer-logo" />
        <StyledIconGroup>
          {redreamerList.map(
            (l) =>
              !l.hidden && (
                <a key={l.key} href={l.url} target="_blank" rel="noreferrer noopener">
                  <img alt={l.key} src={l.default} />
                </a>
              ),
          )}
        </StyledIconGroup>
      </StyledLogo>
      <StyledLogo
        href="https://sphere-meta.com/brand/f105656d-25d2-4151-a27f-5ebc0b977787"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img alt="sphere logo" src={imgSphereLogo} className="sphere-logo" />
        <StyledIconGroup>
          {sphereList.map(
            (l) =>
              !l.hidden && (
                <a key={l.key} href={l.url} target="_blank" rel="noreferrer noopener">
                  <img alt={l.key} src={l.default} />
                </a>
              ),
          )}
        </StyledIconGroup>
      </StyledLogo>
    </StyledContainer>
  );
};

export default FooterInfo;
