import styled from 'styled-components';
import iconLocation from '../../assets/icon_location.svg';
import iconQuantity from '../../assets/icon_cart.svg';
import iconUtilityCategory from '../../assets/icon_category.svg';
import iconRedemptionRule from '../../assets/icon_rules.svg';
import iconNotice from '../../assets/icon_notice.svg';
import { SM_AND_DOWN_QUERY } from '../../configs/breakpoints';
import { useTranslation } from 'react-i18next';

const StyledWrapper = styled.div`
  padding: 0px;

  @media ${SM_AND_DOWN_QUERY} {
    padding: 0;
  }
`;

const StyledContainer = styled.div`
  border: 1px solid white;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  @media ${SM_AND_DOWN_QUERY} {
    width: 100%;
  }
`;

const StyledImageContainer = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;

const StyledContent = styled.div`
  padding: 24px;
  padding-bottom: 0;
  color: white;
  height: 450px;

  .title {
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.brightDreamColor};

    img {
      margin-right: 6px;
    }
  }

  .description {
    font-weight: 700;
    height: 250px;
    font-size: 16px;
    line-height: 24px;
  }

  .location {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;

    &:before {
      display: block;
      width: 24px;
      min-width: 24px;
      content: '';
      background: url(${iconLocation});
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 6px;
      min-width: 24px;
    }
  }

  .quantity {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;

    &:before {
      display: block;
      width: 24px;
      min-width: 24px;
      content: '';
      background: url(${iconQuantity});
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 6px;
      min-width: 24px;
    }
  }

  .utilityCategory {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;

    &:before {
      display: block;
      width: 24px;
      min-width: 24px;
      content: '';
      background: url(${iconUtilityCategory});
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 6px;
      min-width: 24px;
    }
  }

  .redemptionRule {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;

    &:before {
      display: block;
      width: 24px;
      min-width: 24px;
      content: '';
      background: url(${iconRedemptionRule});
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 6px;
      min-width: 24px;
    }
  }

  .redemptionRule {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;

    &:before {
      display: block;
      width: 24px;
      min-width: 24px;
      content: '';
      background: url(${iconRedemptionRule});
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 6px;
      min-width: 24px;
    }
  }

  .notice {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: #ffec00;

    &:before {
      display: block;
      width: 24px;
      min-width: 24px;
      content: '';
      background: url(${iconNotice});
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 6px;
      min-width: 24px;
    }
  }
`;

export const Card = ({
  image,
  title,
  description,
  location,
  quantity,
  utilityCategory,
  redemptionRule,
  notice,
}) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledImageContainer>
          <img alt="campaign" src={image} />
        </StyledImageContainer>
        <StyledContent>
          <div className="title">{title}</div>
          <div className="description">
            {t(description)}
            <ol>
              <li className="location">
                {t('Valid at')} {location}
              </li>
              <li className="quantity">
                {t('Quantity')} {quantity}
              </li>
              <li className="utilityCategory">
                {t('Utility category:')} {utilityCategory}
              </li>
              <li className="redemptionRule">
                {t('Redemption rule:')} {redemptionRule}
              </li>
              <li className="notice">{notice}</li>
            </ol>
          </div>
        </StyledContent>
      </StyledContainer>
    </StyledWrapper>
  );
};
