import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'use-onclickoutside';
import {
  StyledHeader,
  StyledNav,
  StyledContainer,
  StyledImage,
  StyledGroup,
  StyledItem,
  StyledRedreamerButton,
  StyledBackDrop,
  StyledDropdown,
  StyledDropdownContent,
  StyledEarthItem,
} from './Styles';
import logoImage from '../../assets/img_logo_dreampass.svg';
import iconEarth from '../../assets/icon_earth.svg';
import { useResponsive } from '../../hooks/useResponsive';
import { ToggleIcon } from './ToggleIcon';

const Navbar = () => {
  const { isSmallerThanTablet } = useResponsive();
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  useOnClickOutside(dropdownRef, () => setDropdown(false));

  return (
    <>
      {isSmallerThanTablet && open && (
        <StyledBackDrop
          hasBackDrop
          onClick={() => {
            setOpen(false);
          }}
        />
      )}
      <StyledHeader>
        <StyledNav>
          <StyledContainer>
            <StyledImage alt="redreamer logo" src={logoImage} />
            {isSmallerThanTablet && <ToggleIcon onClick={handleClick} open={open} />}
            <StyledGroup isSmallerThanTablet={isSmallerThanTablet} open={open}>
              <StyledItem href="#campaigns">{t('Utility')}</StyledItem>
              <StyledItem href="#gameplay">{t('How to Engage')}</StyledItem>
              <StyledItem href="#partners">{t('Partners')}</StyledItem>
              <StyledItem
                target="_blank"
                rel="noreferrer noopener"
                href="https://opensea.io/collection/uni-pass-1"
              >
                Opensea
              </StyledItem>
              <StyledItem
                target="_blank"
                rel="noreferrer noopener"
                href="https://sphere-meta.com/brand/f105656d-25d2-4151-a27f-5ebc0b977787"
              >
                Dream Pack
              </StyledItem>
              <StyledRedreamerButton href="#gameplay_step2">{t('Redeem')}</StyledRedreamerButton>
              {!isSmallerThanTablet && (
                <StyledEarthItem
                  ref={dropdownRef}
                  onClick={() => {
                    setDropdown(!dropdown);
                  }}
                >
                  <StyledDropdown>
                    <img alt="lang icon" src={iconEarth} />
                  </StyledDropdown>
                  {dropdown && (
                    <StyledDropdownContent>
                      <div
                        className={`item ${i18n.resolvedLanguage === 'en' && 'active'}`}
                        onClick={() => {
                          i18n.changeLanguage('en');
                        }}
                      >
                        English
                      </div>
                      <div
                        className={`item ${i18n.resolvedLanguage === 'zh-Hant' && 'active'}`}
                        onClick={() => {
                          i18n.changeLanguage('zh-Hant');
                        }}
                      >
                        中文
                      </div>
                    </StyledDropdownContent>
                  )}
                </StyledEarthItem>
              )}
              {isSmallerThanTablet && (
                <StyledDropdownContent>
                  <div
                    className={`item ${i18n.resolvedLanguage === 'en' && 'active'}`}
                    onClick={() => {
                      i18n.changeLanguage('en');
                    }}
                  >
                    English
                  </div>
                  <div
                    className={`item ${i18n.resolvedLanguage === 'zh-Hant' && 'active'}`}
                    onClick={() => {
                      i18n.changeLanguage('zh-Hant');
                    }}
                  >
                    中文
                  </div>
                </StyledDropdownContent>
              )}
            </StyledGroup>
          </StyledContainer>
        </StyledNav>
      </StyledHeader>
    </>
  );
};

export default Navbar;
