import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { XS_QUERY } from '../../configs/breakpoints';
import logoImage from '../../assets/img_logo_dreampass.svg';
import bannerNew from './banner_new.png';
import bannerNewMobile from './banner_new_mobile.png';
import imgWords from './img_words.svg';
import { useResponsive } from '../../hooks/useResponsive';
import redreamerImg from './img_redreamer_owner.svg';
import sphereImg from './img_sphere_owner.svg';
import backgroundImg from './img_background.jpg';
import imgInRealLife from './img_in_real_life.svg';

const StyledContainer = styled.div`
  width: 100%;
  height: 770px;
  padding: 78px 121px;
  background-image: url(${backgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${XS_QUERY} {
    overflow: hidden;
    padding: 20px;
    height: 660px;
  }
`;

const StyledBanner = styled.img`
  width: 787px;

  @media ${XS_QUERY} {
    width: 100%;
  }
`;

const StyledText = styled.div`
  font-size: 36px;
  font-weight: 700;
  color: white;

  @media ${XS_QUERY} {
    font-size: 16px;
  }
`;

const StyledTextImage = styled.img`
  width: 394px;

  @media ${XS_QUERY} {
    width: 100%;
  }
`;

const StyledHost = styled.div`
  margin-top: 150px;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 700;
  color: white;
`;

const StyledOwner = styled.img`
  width: 210px;

  @media ${XS_QUERY} {
    width: 150px;
  }
`;

const StyledRow = styled.div`
  display: flex;
`;

const Banner = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  return (
    <StyledContainer>
      <StyledBanner src={logoImage} />
      <StyledText>Unlock exclusive perks in PHYGITAL way</StyledText>
      <StyledTextImage src={imgInRealLife} />
      <StyledHost>Hosts</StyledHost>
      <StyledRow>
        <StyledOwner src={redreamerImg} />
        <StyledOwner src={sphereImg} />
      </StyledRow>
    </StyledContainer>
  );
};

export default Banner;
